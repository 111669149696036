@tailwind base;
@tailwind components;
@tailwind utilities;

.tipCard {
  position: relative;
  background-color: #e8e8e8;
  margin-top: 10px;
  /* // border-radius: 5px; */
  /* // padding: 10px; */
}
.tipCard::after {
  content: "";
  background-color: #e8e8e8;
  width: 30px;
  height: 30px;
  position: absolute;
  transform: rotate(45deg);
  top: -4px;
  right: 20px;
}
